<template>
  <MainContainer>
    <CommonHeader :isHoken="true"/>
    <SectionHeading>申請完了</SectionHeading>
    <ContentsContainer>
      <ContentsText>修理代金サポート（保険）端末事前登録へお申し込みありがとうございます。</ContentsText>
      <ContentsList>
        <ContentsListItem><span class="important">申請番号をお控えいただき</span>、ご不明点は修理代金サポートセンターまでお問い合わせください。</ContentsListItem>
        <ContentsListItem>申請状況はメニュー画面でご確認いただけます。</ContentsListItem>
        <ContentsListItem>申請内容が補償の条件を満たさない場合や申請に不備がある場合は<span class="important">SMSでお知らせ</span>いたします。</ContentsListItem>
      </ContentsList>
      <NumberContainer>
        <span class="label">事前申請番号</span>
        <br>
        <span class="number">{{receiptNumber}}</span>
      </NumberContainer>
      <PontaPass />
      <CommonSupport />
    </ContentsContainer>
    <CommonFooter/>
    <CommonLoading v-if="isLoading" />
  </MainContainer>
</template>

<script>
import CommonHeader from '@/components/common/CommonHeader'
import CommonFooter from '@/components/common/CommonFooter'
import CommonSupport from '@/components/common/CommonSupport'
import CommonLoading from '@/components/common/CommonLoading'
import MainContainer from '@/components/container/MainContainer'
import ContentsContainer from '@/components/container/ContentsContainer'
import NumberContainer from '@/components/container/NumberContainer'
import SectionHeading from '@/components/heading/SectionHeading'
import ContentsText from '@/components/text/ContentsText'
import ContentsList from '@/components/text/ContentsList.vue';
import ContentsListItem from '@/components/text/ContentsListItem.vue';
import {postUtilAuthApi} from '@/utils/ApiHelper'
import {$_resultError, $_isError} from '@/utils/Error'
import PontaPass from '@/components/banner/PontaPass.vue';

export default {
  name: "rep170",
  components: {
    CommonHeader,
    CommonFooter,
    CommonSupport,
    CommonLoading,
    MainContainer,
    ContentsContainer,
    NumberContainer,
    SectionHeading,
    ContentsText,
    ContentsList,
    ContentsListItem,
    PontaPass
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    receiptNumber() {
      return this.$store.getters.getReceiptNumber
    },
  },
  mounted () {
    this.isLoading = true
    postUtilAuthApi()
    .then(res => {
      const result = res.data.result
      this.resultError = $_resultError(result)
      $_isError(this.resultError, this.$route)
      this.isLoading = false
    })
    .catch(() => {
      this.$router.push({name: 'rep320', query: {'p': this.$route.query.p}})
    })
  }
}
</script>
<style lang="scss" scoped>
</style>
