<template>
  <div class="ponta-pass">
    <img src="@/assets/images/banner_pontapass.png" alt="他にもおトクな特典盛りだくさん！さらにPontaパスを楽しもう！" class="img">
    <a class="link" href="https://pass-app.go.link/top?adj_t=1kf13n3d_1kz6as8s&adj_fallback=https%3A%2F%2Fpass.auone.jp%2Fmain%2F&adj_redirect_macos=https%3A%2F%2Fpass.auone.jp%2Fmain%2F">Pontaパス ホームへ</a>
  </div>
</template>

<script>
export default {
  name: 'PontaPass'
};
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
.ponta-pass {
  margin-top: 35px;
  padding-bottom: 20px;
  background-color: #F8F8F8;
  img {
    width: 100%;
    height: auto;
  }

  .link {
    display: block;
    width: calc(100% - 40px);
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 15px;
    border-radius: 26px;
    border: 2px solid #006FC9;
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
    text-align: center;
    color: #006FC9;
    text-decoration: none;
  }
}
</style>
