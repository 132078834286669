<template>
  <form class="form-file-select" :class="{'is-file':file && !error, 'is-error':error}" @submit="() => false" @reset="resetFile">
    <div class="img">
      <img v-if="image" :src="image" alt="">
    </div>
    <span v-if="file" class="file">{{ file.name }}</span>
    <label :for="name" class="button">ファイルを選択</label>
    <input
      :id="name"
      type="file"
      accept="image/jpeg,application/pdf,image/png,image/bmp,image/heic"
      :name="name"
      :disabled=disabled
      @change="updateValue"
    >
  </form>
</template>

<script>


import iconFileSerial from '@/assets/images/icon_file_serial.png';
import iconFileScreen from '@/assets/images/icon_file_screen.png';
import iconFileCamera from '@/assets/images/icon_file_camera.png';

export default {
  name: 'FormFileSelect',
  props: {
    name: { type: String, require: true, default: '' },
    disabled: { type: Boolean, require: false, 'default': false },
    error: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      file: null
    }
  },
  emits: ['change'],
  methods: {
    updateValue: function(e) {
      const files = e.target.files || e.dataTransfer.files;
      this.file = files[0];
      this.$emit('change', files, e.target, this.$el);
    },
    resetFile: function() {
      this.file = null;
    }
  },
  computed : {
    image() {
      const imageMap = {
        deviceImageFilePath1: iconFileSerial,
        deviceImageFilePath2: iconFileScreen,
        deviceImageFilePath3: iconFileCamera,
      }
      return imageMap[this.name];
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
.form-file-select {
  padding: 27px 20px;
  background-color: rgba($black-01, 0.6);
  border-radius: 8px;

  &.is-file {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &.is-error {
    border: 4px solid $red-text;
  }

  .img {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    img {
      width: 100%;
      height: auto;
      border-radius: 8px;
    }
  }

  .button {
    display: block;
    width: fit-content;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 20px;
    color: $white-01;
    border: 1px solid $white-01;
    border-radius: 8px;
    cursor: pointer;
  }

  .file {
    display: block;
    margin-top: 10px;
    font-size: 16px;
    color: $white-01;;
    text-align: center;
    word-break: break-all;
  }

  input[type="file"] {
    display: none;
  }
  
  + .form-file-select {
    margin-top: 15px;
  }
}


</style>
