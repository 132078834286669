<template>
  <li class="contents-list-item"><slot></slot></li>
</template>

<script>
export default {
  name: 'ContentsListItem'
};
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
.contents-list-item {
  padding-left: 1em;
  text-indent: -1em;
  font-size: 15px;
  line-height: 1.4;
  &::before {
    content: "・";
  }
  > :deep(span.important) {
    color: $orange-02;
  }

  + .contents-list-item {
    margin-top: 5px;
  }
}
</style>
