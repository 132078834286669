<template>
  <div class="item-container"><slot></slot></div>
</template>

<script>
export default {
  name: 'item-container'
}
</script>

<style lang="scss" scoped>
.item-container {
  + .item-container {
    margin-top: 25px;
  }
  :deep(.heading-label) {
    margin-bottom: 10px;
  }
  :deep(.contents-text) {
    + .form-select {
      margin-top: 10px;
    }
    + .form-select + .form-select {
      margin-top: 10px;
    }
    + .form-file {
      margin-top: 10px;
    }
    + .form-input {
      margin-top: 10px;
    }
    + .form-checkbox {
      margin-top: 10px;
    }
    + .form-date {
      margin-top: 10px;
    }
    + .form-bank {
      margin-top: 10px;
    }
    + .contents-text {
      margin-top: 10px;
    }
    + .message-box {
      margin-top: 13px;
    }
    + .button-toggle {
      margin-top: 15px;
    }
    + .form-file-select {
      margin-top: 5px;
    }
  }
  :deep(.form-input) + .contents-text {
    margin-top: 15px;
  }
  :deep(.form-textfield) {
    + .contents-text {
      margin-top: 10px;
    }
  }
  :deep(.form-select) {
    + .form-select {
      margin-left: 10px;
    }
    + .contents-text {
      margin-top: 15px;
    }
  }
  :deep(.form-date) {
    + .form-checkbox {
      margin-top: 10px;
    }
    + .contents-text {
      margin-top: 10px;
    }
  }
  :deep(.form-checkbox) + .form-textfield {
    margin-top: 10px;
  }
  :deep(.form-radio) {
    + .form-major-bank {
      margin-top: 25px;
    }
  }
  :deep(.form-major-bank) {
    +.contents-text {
      margin-top: 25px;
    }
  }
  :deep(.error-message) {
    margin-top: 10px;
    margin-bottom: 20px;
    + .contents-text {
      margin-top: 10px;
    }
    + .error-message {
      margin-top: -10px;
    }
  }
  :deep(.check-text) {
    + .small-text {
      display: block;
      margin-top: 9px;
    }
  }
}
</style>
