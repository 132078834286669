<template>
  <MainContainer>
    <CommonHeader/>
    <div v-show="isEdit">
      <SectionHeading>端末情報事前登録</SectionHeading>
      <ContentsContainer>
        <ContentsText variant="center">登録に必要な情報を入力してください</ContentsText>
        <ErrorBox v-if="isError">
          <p v-for="(message, index) in errorMessages" :key="index">{{message.errorMessage}}</p>
        </ErrorBox>

        <ItemContainer>
          <HeadingLabel>ご契約者名/被保険者名</HeadingLabel>
          <FormInput
              v-model="form.insuredName"
              name="insuredName"
              type="text"
              placeholder="英雄太郎"
              maxlength="60"
              :error="'insuredName' in errorMessage"
          ></FormInput>
          <ErrorMessage v-if="'insuredName' in errorMessage">{{errorMessage.insuredName}}</ErrorMessage>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>連絡先電話番号</HeadingLabel>
          <FormInput
              v-model="form.tel"
              name="tel"
              type="text"
              placeholder="08000000000"
              maxlength="11"
              :error="'tel' in errorMessage"
          ></FormInput>
          <ErrorMessage v-if="'tel' in errorMessage">{{errorMessage.tel}}</ErrorMessage>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>補償対象とする端末</HeadingLabel>
          <FormSelect
              v-model="form.deviceKind"
              name="deviceKind"
              :options="mobileOption"
              :error="'deviceKind' in errorMessage"
              @input="selectDeviceKind"
          ></FormSelect>
          <FormSelect
              v-model="form.device"
              name="device"
              v-if="form.deviceKind === 1"
              :disabled="form.deviceKind !== 1"
              :options="modelTypeListOption"
              :error="'device' in errorMessage"
          ></FormSelect>
          <ErrorMessage v-if="'deviceKind' in errorMessage && !form.deviceKind">{{errorMessage.deviceKind}}</ErrorMessage>
          <ErrorMessage v-if="'device' in errorMessage && form.deviceKind">{{errorMessage.device}}</ErrorMessage>
        </ItemContainer>

        <ItemContainer>
          <HeadingLabel>シリアル番号のわかる写真<ButtonHelp href="https://prcp.pass.auone.jp/anshin/android_info/serial.html" target="_blank" /></HeadingLabel>
          <FormFileSelect
              name="deviceImageFilePath1"
              :error="'deviceImageFilePath1' in errorMessage && errorMessage.deviceImageFilePath1 !== ''"
              @change="setImageFile"
          ></FormFileSelect>
          <ErrorMessage v-if="'deviceImageFilePath1' in errorMessage && errorMessage.deviceImageFilePath1 !== ''">{{errorMessage.deviceImageFilePath1}}</ErrorMessage>
        </ItemContainer>
        
        <ItemContainer>
          <HeadingLabel>本体写真（画面側 / カメラ側）<ButtonHelp href="https://prcp.pass.auone.jp/anshin/android_info/camera.html" target="_blank" /></HeadingLabel>
          <FormFileSelect
              name="deviceImageFilePath2"
              :error="'deviceImageFilePath2' in errorMessage && errorMessage.deviceImageFilePath2 !== ''"
              @change="setImageFile"
          ></FormFileSelect>
          <ErrorMessage v-if="'deviceImageFilePath2' in errorMessage && errorMessage.deviceImageFilePath2 !== ''">{{errorMessage.deviceImageFilePath2}}</ErrorMessage>

          <FormFileSelect
              name="deviceImageFilePath3"
              :error="'deviceImageFilePath3' in errorMessage && errorMessage.deviceImageFilePath3 !== ''"
              @change="setImageFile"
          ></FormFileSelect>
          <ErrorMessage v-if="'deviceImageFilePath3' in errorMessage && errorMessage.deviceImageFilePath3 !== ''">{{errorMessage.deviceImageFilePath3}}</ErrorMessage>
        </ItemContainer>

        <ButtonContainer>
          <ButtonMain variant="primary" @click="confirm">確認画面へ</ButtonMain>
        </ButtonContainer>
        <CommonSupport/>
      </ContentsContainer>
    </div>

    <div v-show="isConfirm">
      <SectionHeading>端末情報事前登録の確認</SectionHeading>
      <ContentsContainer>
        <ContentsText variant="center">次の内容で申請します</ContentsText>
        <ItemContainer>
          <HeadingLabel>ご契約者名/被保険者名</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{form.insuredName}} さま</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>連絡先電話番号</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{form.tel}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>補償対象とする端末</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{form.device}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>シリアル番号</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{fileName(file.deviceImageFilePath1)}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>本体写真（画面側）</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{fileName(file.deviceImageFilePath2)}}</ContentsText>
        </ItemContainer>
        <ItemContainer>
          <HeadingLabel>本体写真（カメラ側）</HeadingLabel>
          <ContentsText variant="bold" :breakword="true">{{fileName(file.deviceImageFilePath3)}}</ContentsText>
        </ItemContainer>

        <ButtonContainer>
          <ButtonMain variant="next" @click="submit">申請する</ButtonMain>
          <ButtonMain @click="edit">修正する</ButtonMain>
        </ButtonContainer>
        <CommonSupport/>
      </ContentsContainer>
    </div>

    <CommonFooter/>

    <CommonLoading v-if="isLoading"/>
  </MainContainer>
</template>

<script>
  import {
    postClaimInitApi,
    postClaimEntryApi,
    postClaimEntryCheckApi,
    postClaimEntryInsertApi,
    postClaimEntryUpdateApi,
  } from '@/utils/ApiHelper'
  import {PREFECTURES} from '@/utils/Constant'
  import {$_resultError, $_validationErrors, $_validationErrorMessage, $_errorMessages, $_isError} from '@/utils/Error'
  import {$_setUploadImageFile, $_fileName} from '@/utils/UploadImage'

  import CommonHeader from '@/components/common/CommonHeader'
  import CommonFooter from '@/components/common/CommonFooter'
  import CommonSupport from '@/components/common/CommonSupport'
  import CommonLoading from '@/components/common/CommonLoading'
  import MainContainer from '@/components/container/MainContainer'
  import ContentsContainer from '@/components/container/ContentsContainer'
  import ItemContainer from '@/components/container/ItemContainer'
  import SectionHeading from '@/components/heading/SectionHeading'
  import HeadingLabel from '@/components/heading/HeadingLabel'
  import ContentsText from '@/components/text/ContentsText'
  import FormInput from '@/components/form/FormInput'
  import FormSelect from '@/components/select/FormSelect.vue';
  import FormFileSelect from '@/components/form/FormFileSelect.vue';
  import ButtonContainer from '@/components/button/ButtonContainer'
  import ButtonMain from '@/components/button/ButtonMain'
  import ErrorBox from '@/components/error/ErrorBox'
  import ErrorMessage from '@/components/error/ErrorMessage'
  import ButtonHelp from '@/components/button/ButtonHelp.vue';

  export default {
    name: "rep140",
    components: {
      CommonHeader,
      CommonFooter,
      CommonSupport,
      CommonLoading,
      MainContainer,
      ContentsContainer,
      ItemContainer,
      SectionHeading,
      HeadingLabel,
      ContentsText,
      FormInput,
      FormSelect,
      FormFileSelect,
      ButtonMain,
      ButtonContainer,
      ErrorBox,
      ErrorMessage,
      ButtonHelp
    },
    data() {
      return {
        status: 'edit',
        form: {
          registrationReservationId: '',
          insuredName:"",
          tel: "",
          postalCode: "",
          address1: "",
          address2: "",
          address3: "",
          deviceKind: 0,
          device: ""
        },
        file: {
          deviceImageFilePath1: '',
          deviceImageFilePath2: '',
          deviceImageFilePath3: '',
        },
        modelList: [],
        isLoading: false,
        resultError: []
      }
    },
    created() {
      window.addEventListener('beforeunload', this.beforeunload)
    },
    destroyed() {
      window.removeEventListener('beforeunload', this.beforeunload)
    },
    computed: {
      modelTypeListOption() {
        let options = [{label: '選択してください', value: '', disabled: true}]
        for (let model of this.modelList) {
          options.push({label: model, value: model, disabled: false})
        }
        return options
      },
      mobileOption() {
        let options = [{label: '選択してください', value: 0, disabled: true}]
        options.push({label: "iPhone", value: 1, disabled: false})
        options.push({label: "iPad", value: 2, disabled: false})
        return options
      },
      stateOption() {
        let options = [{label: '選択してください', value: '', disabled: true}]

        for (const prefecture of PREFECTURES) {
          options.push({label: prefecture, value: prefecture, disabled: false})
        }
        return options
      },
      advanceEntryNumber () {
        return this.$store.getters.getAdvanceEntryNumber
      },
      isUpdate () {
        return this.$store.getters.getIsUpdate
      },
      isEdit() {
        return this.status === 'edit'
      },
      isConfirm() {
        return this.status === 'confirm'
      },
      address() {
        return `〒${this.form.postalCode} ${this.form.address1}${this.form.address2}${this.form.address3}`
      },
      errors() {
        return $_validationErrors(this.resultError)
      },
      isError() {
        return this.errors.length > 0
      },
      errorMessage() {
        return $_validationErrorMessage(this.resultError)
      },
      errorMessages () {
        return $_errorMessages(this.resultError)
      }
    },
    mounted() {
      this.isLoading = true
      postClaimInitApi().then(res => {
        const result = res.data.result
        this.resultError = $_resultError(result)
        if ($_isError(this.resultError, this.$route)) {
          this.isLoading = false
        } else {
          this.form.registrationReservationId = result.registrationReservationId
          this.modelList = result.modelList
          this.claimEntry()
        }
      })
      .catch(() => {
        this.$router.push({ name: 'rep320', query: {'p': this.$route.query.p} })
      })
    },
    methods: {
      claimEntry() {
        if (!this.isUpdate) {
          this.isLoading = false
          return
        }
        postClaimEntryApi(this.form.registrationReservationId)
        .then(res => {
          const result = res.data.result
          this.resultError = $_resultError(result)
          $_isError(this.resultError, this.$route)
          for (let key in result) {
            if (Object.prototype.hasOwnProperty.call(this.form, key)) {
              this.form[key] = result[key]
            }
          }
          this.isLoading = false
        })
        .catch(() => {
          this.$router.push({ name: 'rep320', query: {'p': this.$route.query.p} })
        })
      },
      selectDeviceKind(event) {
        if (event.target.value === '2') {
          this.form.device = 'iPad'
        } else {
          this.form.device = ''
        }
      },
      beforeunload(event) {
        event.returnValue = ''
      },
      setImageFile(fileList, el, form) {
        // UploadImageMixin
        $_setUploadImageFile(fileList, el, form, this)
      },
      confirm() {
        if (this.isLoading) return false

        this.isLoading = true
        this.resultError = []
        postClaimEntryCheckApi(this.form)
          .then(res => {
            const result = res.data.result
            this.resultError = $_resultError(result)
            if (!$_isError(this.resultError, this.$route)) {
              this.status = 'confirm'
            }
            this.scrollTop()
            this.isLoading = false
          })
          .catch(() => {
            this.$router.push({ name: 'rep320', query: {'p': this.$route.query.p} })
          })
      },
      submit() {
        if (this.isLoading) return false
        this.isLoading = true
        this.isUpdate ? this.updateEntry() : this.insertEntry()
      },
      insertEntry() {
        postClaimEntryInsertApi(this.form)
        .then(res => {
          const result = res.data.result
          this.resultError = $_resultError(result)
          if (!$_isError(this.resultError, this.$route)) {
            this.$store.dispatch('setReceiptNumber', result.advanceEntryNumber)
            this.$router.push({ name: 'rep170', query: {'p': this.$route.query.p} })
          }
          this.isLoading = false
        })
        .catch(() => {
          this.$router.push({ name: 'rep320', query: {'p': this.$route.query.p} })
        })
      },
      updateEntry() {
        postClaimEntryUpdateApi({...this.form, advanceEntryNumber: this.advanceEntryNumber})
        .then(res => {
          const result = res.data.result
          this.resultError = $_resultError(result)
          if (!$_isError(this.resultError, this.$route)) {
            this.$store.dispatch('setReceiptNumber', result.advanceEntryNumber)
            this.$router.push({ name: 'rep170', query: {'p': this.$route.query.p} })
          }
          this.isLoading = false
        })
        .catch(() => {
          this.$router.push({ name: 'rep320', query: {'p': this.$route.query.p} })
        })
      },
      edit() {
        this.scrollTop()
        this.status = 'edit'
      },
      scrollTop() {
        this.$scrollTo('body', 1)
      },
      fileName(path) {
        return $_fileName(path)
      }
    }
  }
</script>
