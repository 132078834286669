<template>
  <a 
    class="button-help"
    :href="href"
    :target="target"
  >
    <img src="@/assets/images/icon_help.svg" class="icon-help">
  </a>
</template>

<script>
export default {
  name: 'ButtonHelp',
  props: {
    href: { type: String, default: '' },
    target: { type: String, default: '' }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
.button-help {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-left: 5px;
  .icon-help {
    width: 100%;
    height: auto;
  }
}
</style>