<template>
  <div class="contents-container"><slot></slot></div>
</template>

<script>
export default {
  name: 'contents-container'
}
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";
@import "@/assets/stylesheets/mixins";
.contents-container {
  width: 100%;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  @include mq-up(sm) {
    padding-left: 20px;
    padding-right: 20px;
  }
  :deep(.contents-heading) {
    + .contents-text {
      margin-top: 25px;
    }
    + .item-container {
      margin-top: 25px;
    }
    + .border-container {
      margin-top: 20px;
    }
  }
  :deep(.contents-text) {
    + .contents-text.is-support {
      margin-top: 25px;
    }
    + .heading-label {
      margin-top: 25px;
    }
    + .item-container {
      margin-top: 25px;
    }
    + .error-box {
      margin-top: 20px;
    }
    + .contents-heading {
      margin-top: 35px;
    }
    + .contents-text {
      margin-top: 10px;
    }
    + .button-container {
      margin-top: 35px;
    }
    + .contents-list {
      margin-top: 5px;
    }
  }
  :deep(.error-box) + .item-container {
    margin-top: 20px;
  }
  :deep(.error-box) + .contents-text {
    margin-top: 20px;
  }
  :deep(.number-container) {
    margin-top: 20px;
    margin-bottom: 15px;
  }

  :deep(.item-container) + .button-container {
    margin-top: 35px;
  }

  :deep(.border-container) + .button-container {
    margin-top: 35px;
  }

  :deep(.button-container) {
    + .contents-text {
      margin-top: 35px;
    }
    + .button-pagetop {
      margin-top: 30px;
    }
    + .button-container {
      margin-top: 35px;
    }
  }

  :deep(.common-support) {
    margin-top: 100px;
    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }
}
</style>
